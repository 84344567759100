import React, { useEffect, useState } from "react";
import { TextField, InputLabel } from "@mui/material";
import { NAME, EMAIL, PHONE_NUMBER } from "../../helpers/maxLenghts";
import FileUploader from "../shared/FileUploader";
import AddSignModal from "../payment/AddSingModal";
import { primary } from "../../ui/colors";
import SelectComponent from "../shared/SelectComponent";
import backgroundMobile from "../../assets/AlbumMobileSecond.jpg";
import backgroundDesktop from "../../assets/AlbumDesktopSecond.jpg";
import useMobileDetector from "../../hooks/useMobileDetector";
import { getHighSchools } from "../../services/passengerService";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { database } from "../../config";
import InputMask from "react-input-mask";
import { Button } from "../../ui";
import axios from "axios";
import Modal from "react-modal";
import { categories } from "./surprise-index";

const MARGIN_BOTTOM = 8;
const USD = "usd";

const instagramLogo = require("../../assets/instagram.png");
const tikTokLogo = require("../../assets/tiktok.png");

const image = {
  width: "100%",
  height: "auto",
};

export const SurpriseRegister = ({
  data,
  onChangeData,
  onAcceptTermsAndConditions,
  areTermsAndConditionsAccepted,
}) => {
  const [highschools, setHighschools] = useState();
  const [sent, setSent] = useState();
  const [loading, setLoading] = useState();
  const [editable, setEditable] = useState(false);
  const [validParticipantDocId, setValidParticipantDocId] = useState(false);
  const [modalOpen, setModalOpen] = useState(true);
  const [startGame, setStartGame] = useState(false);

  const isMobile = useMobileDetector();
  const styles = {
    cardsContainer: {
      display: "flex",
      flex: 1,
      height: 100,
      flexDirection: "row",
      gap: 20,
    },
    card: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      border: "1px",
      height: 200,
      border: "1px solid transparent", // Ensure border is always present
      borderRadius: 10,
      borderWidth: 2,
      alignItems: "center",
      justifyContent: "center",
    },
    formContainer: {
      paddingRight: !isMobile ? 300 : 10,
      paddingLeft: !isMobile ? 300 : 10,
    },
    dateInput: {
      paddingTop: 16.5,
      paddingBottom: 16.5,
      fontSize: "1rem",
      paddingLeft: 14,
      paddingRight: 14,
      fontFamily: "Gilroy",
      marginBottom: 8,
      backgroundColor: "transparent",
      borderWidth: 1,
      borderRadius: 4,
      borderColor: "rgba(0, 0, 0, 0.38)",
    },
    subtitle: {
      alignSelf: "center",
    },
    sentContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    socialContainer: {
      display: "flex",
      gap: 20,
      paddingTop: 20,
    },
    socialLogoStyles: {
      width: 40,
      height: "auto",
      cursor: "pointer",
    },
    termsContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: 20,
    },
    title: { alignSelf: "center", textAlign: "center", padding: 25 },
  };

  useEffect(() => {
    getHighSchools().then((res) => {
      setHighschools(res);
    });
  }, []);

  useEffect(() => {
    console.log("Data: ", data);
  }, [data]);

  const sendForm = async () => {
    if (!validParticipantDocId) {
      alert("La cédula del pasajero no es válida.");
      return;
    }
    if (data?.documentId.length < 8) {
      alert("La cédula del participante no es válida.");
      return;
    }

    // Accessing the collection and querying by documentId
    const guessDb = collection(database, "surprise-guess"); // Use collection() for accessing
    const getByDocIdQuery = query(
      guessDb,
      where("documentId", "==", data?.documentId)
    ); // Create a query

    // Execute the query
    const querySnapshot = await getDocs(getByDocIdQuery);

    let exists = false;
    querySnapshot.forEach((doc) => {
      if (doc.data() && doc.data().documentId === data.documentId) {
        exists = true;
      }
      // You can work with the data here
    });

    if (exists) {
      alert("Ya hay una entrada para esa cédula de identidad.");
      return;
    }
    try {
      setLoading(true);
      const docRef = await addDoc(collection(database, "surprise-guess"), data);
      setSent(true);
      onChangeData();
      setLoading(false);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const onChangeParticipantDocument = (value) => {
    onChangeData({
      ...data,
      ["participantDocumentId"]: value,
    });
    if (value.length === 8) {
      axios
        .get(`https://ci-uy.checkleaked.cc/${value}`)
        .then((res) => {
          setValidParticipantDocId(true);
        })
        .catch((e) => {
          console.error("CI no encontrada en registro", value);
          setValidParticipantDocId(false);
        });
    }
  };

  const onChangeDocument = (value) => {
    onChangeData({
      ...data,
      ["documentId"]: value,
    });
    if (value.length === 8) {
      axios
        .get(`https://ci-uy.checkleaked.cc/${value}`)
        .then((res) => {
          const { nombre, apellidos, fechaNacimiento_raw } = res.data?.resp;
          setEditable(false);
          if (nombre && !fechaNacimiento_raw) {
            setEditable(true);
          }
          console.log("data:::", res.data?.resp);
          onChangeData({
            ...data,
            ["documentId"]: value,
            ["name"]: nombre,
            ["lastName"]: apellidos,
            ["birthDate"]: fechaNacimiento_raw,
          });
        })
        .catch((e) => {
          console.error("CI no encontrada en registro", value);
        });
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
      {!areTermsAndConditionsAccepted ? (
        <Modal
          style={{
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              width: isMobile ? 300 : 700,
            },
          }}
          contentLabel="Example Modal"
          isOpen={modalOpen}
          ariaHideApp={false}
        >
          <div style={styles.termsContainer}>
            <h2>Términos y condiciones:</h2>
            <span style={{ fontSize: 10 }}>
              Los familiares de la persona que ganaría el viaje pueden hacerle
              ganar el viaje gratis si aciertan la sorpresa. Cada miembro de la
              familia puede enviar una sola vez el formulario, por lo que
              recomendamos pensar bien antes de hacer el intento.
            </span>
            <span style={{ fontSize: 10 }}>
              En primer lugar, se debe ingresar la cédula de identidad de la
              persona que ganaría el viaje.
            </span>
            <span style={{ fontSize: 10 }}>
              Para ganar el premio se debe acertar la sorpresa de manera EXACTA.
              Se deberá comprobar que el ganador es familiar de la persona que
              gana el viaje antes de recibir el premio.
            </span>
            <Button
              variant="contained"
              onClick={() => {
                onAcceptTermsAndConditions();
                setModalOpen(false);
              }}
            >
              DE ACUERDO
            </Button>
          </div>
        </Modal>
      ) : null}
      <div style={styles.formContainer}>
        {sent ? (
          <div style={styles.sentContainer}>
            <h3 className="verticalPaddingText">Formulario enviado!</h3>
            <span>
              Seguinos en las redes sociales para enterarte de los resultados!
            </span>
            <div style={styles.socialContainer}>
              <img
                src={instagramLogo}
                alt=""
                style={styles.socialLogoStyles}
                onClick={() =>
                  window
                    .open(
                      "https://www.instagram.com/college.uy/?hl=en",
                      "_blank"
                    )
                    .focus()
                }
              ></img>
              <img
                src={tikTokLogo}
                alt=""
                style={styles.socialLogoStyles}
                onClick={() =>
                  window
                    .open(
                      "https://www.instagram.com/college.uy/?hl=en",
                      "_blank"
                    )
                    .focus()
                }
              ></img>
            </div>
          </div>
        ) : (
          <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
            <h2 style={styles.title}>
              ADIVINA LA SORPRESA Y GANATE UN VIAJE GRATIS!
            </h2>
            <h4 style={styles.subtitle}>
              Datos de la persona que ganaría el viaje:
            </h4>
            <TextField
              style={{ marginBottom: MARGIN_BOTTOM }}
              value={data?.participantDocumentId}
              onChange={(e) => onChangeParticipantDocument(e.target.value)}
              placeholder="Cédula de identidad"
              inputProps={{
                maxLength: 8,
              }}
            ></TextField>
            <h4 style={styles.subtitle}>Completa con tus datos:</h4>
            <TextField
              style={{ marginBottom: MARGIN_BOTTOM }}
              value={data?.documentId}
              onChange={(e) => onChangeDocument(e.target.value)}
              placeholder="Cédula de identidad"
              inputProps={{
                maxLength: 8,
              }}
            ></TextField>
            <TextField
              style={{ marginBottom: MARGIN_BOTTOM }}
              value={data?.name}
              onChange={(e) =>
                onChangeData({ ...data, ["name"]: e.target.value })
              }
              placeholder="Nombre"
              inputProps={{
                maxLength: NAME,
                style: { textTransform: "capitalize" },
              }}
            ></TextField>
            <TextField
              style={{ marginBottom: MARGIN_BOTTOM }}
              value={data?.lastName}
              onChange={(e) =>
                onChangeData({ ...data, ["lastName"]: e.target.value })
              }
              placeholder="Apellido"
              inputProps={{
                maxLength: NAME,
                style: { textTransform: "capitalize" },
              }}
            ></TextField>
            <InputMask
              style={styles.dateInput}
              mask="99/99/9999"
              value={data?.birthDate}
              onChange={(val) =>
                onChangeData({ ...data, ["birthDate"]: val.target.value })
              }
              inputProps={{
                maxLength: 9,
                style: { textTransform: "capitalize" },
              }}
              placeholder="Fecha de nacimiento"
            ></InputMask>
            <TextField
              value={data?.phone}
              onChange={(e) =>
                onChangeData({
                  ...data,
                  ["phone"]: e.target.value,
                })
              }
              placeholder="Telefono"
              inputProps={{ maxLength: PHONE_NUMBER }}
            ></TextField>
          </div>
        )}
      </div>
    </div>
  );
};

export const SurpriseGuess = ({ category, onChangeData, data }) => {
  const isMobile = useMobileDetector();
  const { label, key, title, options } = category;
  const styles = {
    title: { alignSelf: "center", padding: 25, textAlign: "center" },
    formContainer: {
      paddingRight: !isMobile ? 300 : 10,
      paddingLeft: !isMobile ? 300 : 10,
    },
  };
  return (
    <div style={styles.formContainer}>
      <h1 style={styles.title}>{title}</h1>
      <h3 style={styles.title}>{label}</h3>
      <SelectComponent
        onChange={(val) =>
          onChangeData({
            ...data,
            [key]: val.id,
          })
        }
        label={title}
        data={options}
        val={data[key]} // Use optional chaining here
      ></SelectComponent>
    </div>
  );
};

export const SurpriseResults = ({ onChangeData, data }) => {
  const isMobile = useMobileDetector();
  const styles = {
    title: { alignSelf: "center", padding: 25 },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      paddingRight: !isMobile ? 300 : 10,
      paddingLeft: !isMobile ? 300 : 10,
    },
  };

  return (
    <div style={styles.formContainer}>
      <h2 style={styles.title}>TUS RESULTADOS!</h2>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ flex: 1 }}>
          <h3 style={{ paddingBottom: 10 }}>Tus datos:</h3>
          <span>
            Nombre:{" "}
            <strong>
              {data.name} {data.lastName}
            </strong>
          </span>
          <br></br>
          <span>
            Cedula: <strong>{data.documentId}</strong>
          </span>
          <br></br>
          <span>
            CI pasajero/a: <strong>{data.participantDocumentId}</strong>
          </span>
        </div>
        <div style={{ flex: 1 }}>
          <h3>Tus apuestas:</h3>
          <div>
            <span>
              Paises:
              <strong>
                {
                  categories[0].options.find((c) => c.id === data.countries)
                    ?.label
                }
              </strong>
            </span>
            <br></br>
            <span>
              Ciudades:{" "}
              <strong>
                {categories[1].options.find((c) => c.id === data.cities)?.label}
              </strong>
            </span>
            <br></br>

            <span>
              Deportes:{" "}
              <strong>
                {categories[3].options.find((c) => c.id === data.sports)?.name}
              </strong>
            </span>
            <br></br>
            <span>
              Famosos:{" "}
              <strong>
                {categories[2].options.find((c) => c.id === data.famous)?.name}
              </strong>
            </span>
            <br></br>
            <span>
              Transportes:{" "}
              <strong>
                {
                  categories[4].options.find((c) => c.id === data.transport)
                    ?.name
                }
              </strong>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
